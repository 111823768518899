<template>
  <component :is="productData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="productData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.products.clientNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-product-cards' }">
          {{ $t("message.products.clientList") }}
        </b-link>
        {{ $t("message.products.forOtherProducts") }}
      </div>
    </b-alert>

    <template v-if="productData">
      <div>
        <b-card>
          <h2 class="mb-4">{{ $t("message.products.editProduct") }}</h2>
          <b-row>
            <b-col md="9">
              <b-form-group label-for="nameProducts">
                <template v-slot:label>
                  {{ $t("message.products.name") }}
                </template>
                <b-form-input
                  v-model="productData.name"
                  label="name"
                  label-for="nameProducts"
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.invoices.value") }}
                </template>
                <b-form-input
                  label="value"
                  v-model="productData.initialValue"
                  v-money="getCurrencyFormat()"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </b-form-group>
            </b-col>

            <b-col md="1">
              <b-form-group label="Active" label-for="userGroup">
                <template v-slot:label>
                  {{ $t("message.user.active") }}
                </template>
                <b-form-checkbox
                  style="margin-left: 7px; margin-top: 12px"
                  v-model="productData.status"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Descrição" label-for="descriptionTasks">
                <template v-slot:label>
                  {{ $t("message.products.desc") }}
                </template>
                <quill-editor
                  id="title"
                  style="margin-bottom: 52px; height: 200px"
                  :options="editorOption"
                  v-model="productData.description"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="projectId">
                <template v-slot:label>
                  {{ $t("message.products.projects") }}
                </template>
                <v-select
                  multiple
                  v-model="productData.projects"
                  :options="projectOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="projectId">
                <template v-slot:label>
                  {{ $t("message.products.contracts") }}
                </template>
                <v-select
                  multiple
                  v-model="productData.contracts"
                  :options="contractOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
    <div class="text-right">
      <b-button
        v-if="productData.id"
        variant="primary"
        @click="updateProduct()"
        :to="{ name: 'apps-product-view', params: { id: productData.id } }"
      >
        <!-- <b-button variant="primary" @click="updateEmployee()"> -->
        {{ $t("message.buttons.save") }}</b-button
      >
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormTextarea,
    vSelect,
    quillEditor,
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      //select \\
      projectOptions: [],
      contractOptions: [],
      //multilingual i18n \\
      locale: this.$i18n.locale,
      productData: [],
      userData: store.state.user.userData,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  async created() {
    await axios({
      method: "get",
      url: `${URL_API}product/${router.currentRoute.params.id}`,
      headers: getHeader(this.userData),
    })
      .then((response) => {
        this.productData = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.productData = undefined;
        }
      });

    var projectList = [];
    var projectId = [];
    await axios
      .get(`${URL_API}project/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          projectList.push(element);
          projectId.push(id);
          this.projectOptions.push({
            label: projectList[i],
            value: projectId[i],
          });
        }
      });

    var contractList = [];
    var contractId = [];
    await axios
      .get(`${URL_API}contract/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status) {
            const element = response.data[i].name;
            const id = response.data[i].id;
            contractList.push(element);
            contractId.push(id);
            this.contractOptions.push({
              label: contractList[i],
              value: contractId[i],
            });
          }
        }
      });
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },

    updateProduct() {
      if (this.$i18n.locale === "en") {
        this.productData.initialValue = Number(
          this.productData.initialValue.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      } else {
        this.productData.initialValue = this.switchDotsAndCommas(
          this.productData.initialValue
        );
        this.productData.initialValue = Number(
          this.productData.initialValue.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      }

      axios({
        method: "put",
        url: `${URL_API}product/${this.productData.id}`,
        headers: getHeader(this.userData),
        data: this.productData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },
  },
};
</script>
